<i18n lang="json">{
    "en": {
        "copyright": "© 2025 Augwa",
        "privacyPolicy": "Privacy policy",
        "termsOfUse": "Terms of use"
    }
}</i18n>

<template>
    <footer class="bg-primary">
        <div class="flex mx-auto my-1 max-w-7xl p-2 lg:px-8 justify-between gap-4">
            <div>
                {{ t('copyright') }}
            </div>
            <div class="flex gap-4">
                <NuxtLink :to="localePath('terms')">
                    {{ t('termsOfUse') }}
                </NuxtLink>
                <NuxtLink :to="localePath('privacy')">
                    {{ t('privacyPolicy') }}
                </NuxtLink>
            </div>
        </div>
    </footer>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const localePath = useLocalePath()
</script>